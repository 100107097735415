import { vapi } from './config';
import { setupVapiEvents } from './events';

// Setup events when this file loads
setupVapiEvents();

// Start a Vapi call
export const startCall = (firstName, studySlug) => {
  const serverUrl = `${window.location.origin}/vapi/webhook`; 
  
  vapi.start("9b16eb03-890e-4796-85e5-2521041d9e99", {
    variableValues: {
      first_name: firstName,
      study_id: studySlug
    },
    serverUrl: serverUrl
  });
};

// Stop a Vapi call
export const stopCall = () => {
  vapi.stop();
};

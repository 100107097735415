import { vapi } from '../../lib/vapi/config';

// Interview Assistant Configuration
export const INTERVIEW_ASSISTANT_ID = "your-interview-assistant-id";

// Setup Interview Buttons and UI
export const setupInterviewButtons = () => {
  const startButton = document.getElementById("start-call");
  const stopButton = document.getElementById("stop-call");
  const webcamContainer = document.getElementById('webcam-container');
  const testButtons = document.getElementById('test-buttons');

  if (startButton) {
    const firstName = startButton.dataset.firstName;
    const studySlug = startButton.dataset.studySlug;
    const interviewSlug = startButton.dataset.interviewSlug;

    startButton.addEventListener("click", () => {
      startCall(firstName, studySlug, interviewSlug);
      startButton.style.display = "none";
      if (stopButton) stopButton.style.display = "";
    });
  }

  if (stopButton) {
    stopButton.addEventListener("click", () => {
      stopCall();
      if (startButton) startButton.style.display = "";
      stopButton.style.display = "none";
    });
  }
};

// Interview Functions
export const startInterview = async (firstName, studySlug, interviewSlug) => {
  try {
    const config = getInterviewConfig(firstName);
    const overrides = getInterviewOverrides(firstName, studySlug, interviewSlug);
    
    await vapi.start(config, overrides);
    console.log("Interview initiated");
    startWebcamDisplay();
  } catch (error) {
    console.error("Error starting interview:", error);
  }
};

export const stopInterview = () => {
  try {
    vapi.stop();
    stopWebcamDisplay();
    console.log("Interview stopped");
  } catch (error) {
    console.error("Error stopping interview:", error);
  }
};

// Webcam Functions
export const startWebcamDisplay = () => {
  navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      const webcamVideo = document.getElementById('webcam-video');
      if (webcamVideo) {
        webcamVideo.srcObject = stream;
        webcamVideo.style.transform = 'scaleX(-1)'; // Mirror mode
        document.getElementById('webcam-container').style.display = 'block';
      }
    })
    .catch(error => console.error("Error accessing webcam:", error));
};

export const stopWebcamDisplay = () => {
  const webcamVideo = document.getElementById('webcam-video');
  if (webcamVideo && webcamVideo.srcObject) {
    webcamVideo.srcObject.getTracks().forEach(track => track.stop());
    webcamVideo.srcObject = null;
  }
  const webcamContainer = document.getElementById('webcam-container');
  if (webcamContainer) webcamContainer.style.display = 'none';
};

// Configuration
export const getInterviewConfig = (firstName) => ({
  name: "AI Interview Assistant",
  firstMessage: `Hello ${firstName}, thank you for participating in this interview. How are you doing today?`,
  transcriber: {
    provider: "deepgram",
    model: "nova-2",
    language: "en-US",
  },
  voice: {
    provider: "playht",
    voiceId: "jennifer",
  },
  model: {
    provider: "openai",
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: `You are an AI assistant conducting an interview. The interviewee's first name is ${firstName}.`
      },
    ],
  },
});

export const getInterviewOverrides = (firstName, studySlug, interviewSlug) => ({
  variableValues: {
    first_name: firstName,
    study_id: studySlug,
    interview_id: interviewSlug,
  },
  server: {
    url: `${window.location.origin}/vapi/webhook`
  },
  artifactPlan: {
    videoRecordingEnabled: true,
    recordingS3PathPrefix: `interviews/${studySlug}/`
  }
});

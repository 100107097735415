import { startCall, stopCall } from '../../lib/vapi/index';

// Study Assistant Configuration
export const STUDY_ASSISTANT_ID = "9b16eb03-890e-4796-85e5-2521041d9e99";

// Setup Study Buttons
export const setupStudyButtons = () => {
  const startButton = document.getElementById("start-call");
  const stopButton = document.getElementById("stop-call");

  if (startButton) {
    const firstName = startButton.dataset.firstName;
    const studySlug = startButton.dataset.studySlug;

    startButton.addEventListener("click", () => {
      startCall(firstName, studySlug);
      startButton.style.display = "none";
      if (stopButton) {
        stopButton.style.display = "";
      }
    });
  }

  if (stopButton) {
    stopButton.addEventListener("click", () => {
      stopCall();
      if (startButton) startButton.style.display = "";
      stopButton.style.display = "none";
    });
  }
}; 
import { vapi } from './config';

export const setupVapiEvents = () => {
  // Track whether a call is currently in progress
  let callStarted = false;

  // Fired when the Vapi call is successfully initiated and connected
  vapi.on("call-start", () => {
    console.log("Call has started");
    callStarted = true;
  });

  // Fired when the AI assistant begins speaking
  vapi.on("speech-start", () => {
    console.log("Assistant speech has started");
  });

  // Fired when the AI assistant finishes speaking
  vapi.on("speech-end", () => {
    console.log("Assistant speech has ended");
  });

  // Fired when a message is received from the AI assistant
  vapi.on("message", (message) => {
    console.log("Message received:", message);
  });

  // Fired when there are updates to call status
  vapi.on("status-update", (statusUpdate) => {
    console.log("Status update:", statusUpdate);
  });

  // Fired when the call ends
  vapi.on("call-end", (report) => {
    console.log("Call ended:", report);
    callStarted = false;
  });
};